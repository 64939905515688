<template>
    <div class="product-mobile-list-view">
        <p class="h4 font-weight-bold m-1">{{ $t('almost_yours') }}</p>
        <p class="font-weight-bold w-100 small">{{ $t('placed_product_in_cart') }}</p>

        <div class="product-image d-flex align-items-center mt-1">
            <img :src="product.image.url" :alt="product.name">
            <p class="mb-0 text-break text-dark w-50 font-weight-bold">{{ product.name }}</p>
            <p class="price font-weight-bold text-dark ml-auto">{{ formatCurrency(minPrice) }}</p>
        </div>

        <div class="product-view-details ">

            <div class="product-view-action">
                <div class="d-flex flex-column justify-content-end">
                    <div v-if="type === 'compare'">
                        <router-link to="/compare"><button class="product-compare" type="button">
                                {{ $t('view_comparison_list') }}
                            </button>
                        </router-link>
                    </div>
                    <router-link to="/order/" v-else>
                        <button :class="type === 'cart' ? 'bg-success' : ''" class="btn rounded-lg w-100 mt-3  text-white">
                            {{ $t('continue_ordering') }}
                        </button>
                    </router-link>
                    <button @click="$emit('handleClose')" class="btn rounded-lg bg-dark w-100 mt-1 text-white">
                            {{ $t('continue_shopping') }}
                    </button>
                    <!-- <b-link @click="$emit('handleClose')" class="continue_shopping float-right">
                        <font-awesome-icon icon="fa-solid fa-angle-left" /> {{
                $t('continue_shopping') }}</b-link> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: Object,
        type: String,
        heading: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            qty: 1,
            maxPrice: this.product.price_range.maximum_price.regular_price.value,
            minPrice: this.product.price_range.maximum_price.final_price.value,
        }
    },
    computed: {
        compareProducts() {
            return this.$store.getters["product/getProductCompare"]
        },
        isCompare() {
            return this.compareProducts.some((product) => product.sku == this.product.sku)
        },
    },
    methods: {
        formatCurrency(amount) {
            return this.$helpers.formatCurrency(amount);
        },
        changeQty: function (current) {
            this.qty = current;
        }
    }
}
</script>