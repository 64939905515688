<template>
  <div class="product-home m-3">
    <router-link :to="`/${product.url_key}`">
      <div class="product-home-image">
        <img
          :src="product.thumbnail ? product.thumbnail.large : product.image.url"
          alt="productImage"
        />
      </div>
      <div class="product-home-meta m-1">
        <p class="title" :title="product.name">{{ productName }}</p>
        <p class="status" v-if="product.stock_status === 'IN_STOCK'">
          {{ $t("in_stock") }}
        </p>
        <p class="status text-danger" v-else-if="product.stock_status">
          {{ $t("out_of_stock") }}
        </p>
      </div>
      <div class="product-home-action m-1">
        <div>
          <p
            class="sale-price"
            v-if="
              product.price_range &&
              product.price_range.maximum_price.final_price.value <
                product.price_range.maximum_price.regular_price.value
            "
          >
            {{
              formatCurrency(
                product.price_range.maximum_price.regular_price.value
                  ? product.price_range.maximum_price.regular_price.value
                  : 0
              )
            }}
          </p>
          <p class="price">
            {{
              formatCurrency(
                product.price_range &&
                  product.price_range.maximum_price.final_price.value
                  ? product.price_range.maximum_price.final_price.value
                  : 0
              )
            }}
          </p>
        </div>
      </div>
    </router-link>

    <button
      class="product-home-cart"
      @click="addToCart"
      :disabled="!product.stock_qty || product.stock_qty === 0"
    >
      <font-awesome-icon icon="fa-solid fa-basket-shopping" />
    </button>

    <!-- badges -->
    <div class="product-home-badges" v-if="isCompare">
      <font-awesome-icon
        class="pr-4 pointer"
        icon="fa-solid fa-close"
        @click="removeCompare()"
      />
    </div>
    <div class="product-home-badges" v-else>
      <span
        class="product-home-label py-2 label-out"
        v-if="product.stock_qty <= 0"
        >{{ $t("out_of_stock") }}</span
      >
      <span
        class="product-home-label py-2"
        v-for="label in product.labels"
        :key="label.id"
        :style="label.product.css"
        >{{ label.product.text }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
    isCompare: { type: Boolean, default: false },
    nameMaxLength: { type: Number, default: 20 },
  },
  computed: {
    productName() {
      if (this.product.name.length <= this.nameMaxLength) {
        return this.product.name;
      } else {
        const words = this.product.name.split(" ");
        let shortenedString = "";
        let count = 0;
        for (let i = 0; i < words.length; i++) {
          if (count + words[i].length <= this.nameMaxLength) {
            shortenedString += words[i] + " ";
            count += words[i].length;
          } else {
            break;
          }
        }
        return shortenedString.trim() + "...";
      }
    },
  },
  methods: {
    formatCurrency(amount) {
      return this.$helpers.formatCurrency(amount);
    },
    async addToCart() {
      if (this.product.__typename == "SimpleProduct") {
        const item =
          '{data: {sku: "' + this.product.sku + '", quantity:' + 1 + "}}  ";
        let retval = await this.$store.dispatch("cart/addToCart", {
          type: this.product.__typename,
          item: item,
        });

        if (retval == false) {
          const msg = {
            type: "danger",
            title: this.$t("shopping_basket"),
            text: this.$t("not_add_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        } else {
          const msg = {
            type: "success",
            title: this.$t("shopping_basket"),
            text: this.$t("added_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      } else {
        this.$router.push("/" + this.product.url_key + "/");
      }
    },
    removeCompare() {
      this.$store.dispatch("product/removeCompareProduct", {
        sku: this.product.sku,
      });
      const msg = {
        type: "success",
        title: "Success",
        text: "Product removed from compare list",
      };
      this.$store.dispatch("messages/sendMessage", { message: msg });
    },
  },
  mounted() {
    console.log(this.product, "Product in Product-Home");
  },
};
</script>

