<template>
  <main class="main">
    <breadcrumb :current="getName"></breadcrumb>

    <div class="page-content">
      <div class="container skeleton-body horizontal">
        <div class="product-details-top">
          <!-- <b-alert v-model="showSuccess" variant="success" dismissible>
                        <font-awesome-icon icon="fa-solid fa-circle-check" />
                        {{ $t('welldone') }}
                    </b-alert>
                    <b-alert v-model="showInfo" variant="info" dismissible>
                        <font-awesome-icon icon="fa-solid fa-bell" />
                        Let op!
                    </b-alert>
                    <b-alert v-model="showWarning" variant="warning" dismissible>
                        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                        {{ $t('warning') }}
                    </b-alert>
                    <b-alert v-model="showError" variant="danger" dismissible>
                        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                        {{ $t('failed_try_again') }}
                    </b-alert> -->
          <div class="row skel-pro-single" :class="{ loaded: product != null }">
            <div class="col-md-6 mb-2">
              <h4 class="text-primary mb-0">{{ productName }}</h4>
              <div class="d-flex justify-content-between align-items-center">
                <p class="pointer" @click="goBack()">
                  <font-awesome-icon icon="fa-solid fa-angle-left" />
                  {{ $t("view_everything_from_the_category") }}
                </p>
                <div class="position-relative">
                  <div
                    class="ratings-container pointer mb-0"
                    @click="handleRating"
                  >
                    <div class="ratings mr-2">
                      <div
                        class="ratings-val"
                        :style="{
                          width: (product.rating_summary / 100) * 5 * 20 + '%',
                        }"
                      ></div>
                      <span class="tooltip-text">{{
                        (product.rating_summary / 100) * 5
                      }}</span>
                    </div>
                    <font-awesome-icon icon="fa-solid fa-angle-down" />
                    <span class="ratings-text mt-0"
                      >( {{ product.review_count }} ) {{ $t("review") }}</span
                    >
                  </div>
                  <div
                    class="position-absolute"
                    style="z-index: 1; right: 0; top: 2rem"
                    v-if="ratingDialog"
                  >
                    <overall-rating :product="product" />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end col-md-6 mb-2">
              <div v-if="product.brand">
                <img :src="product.brand.logo" :width="100" alt="brand" />
              </div>
            </div>

            <div class="col-md-6">
              <div class="skel-product-gallery"></div>
              <gallery-horizontal :product="productCache"></gallery-horizontal>
            </div>

            <div class="col-md-6">
              <div class="entry-summary row">
                <div class="col-md-12">
                  <div class="entry-summary1 mt-2 mt-md-0"></div>
                </div>
                <div class="col-md-12">
                  <div class="entry-summary2"></div>
                </div>
              </div>
              <detail-one
                 v-if="product"
                :selected-config-product="
                  selectedConfigProduct && selectedConfigProduct
                "
                :product-cache="productCache"
                @update-product="updateProductCache"
              ></detail-one>
            </div>
          </div>
        </div>
      </div>
      <info-two></info-two>

      <!-- child_products -->
      <div v-if="productCache.__typename == 'BundleProduct'">
        <div v-for="(child, index) in bundlesProducts" :key="index">
          <div v-if="child && child.options && child.options.length > 0">
            <!-- <div class="child-product-section">
        <p><strong>{{ child.title }}</strong></p>
        
        <div v-for="(option, optionIndex) in child.options" :key="optionIndex">
          <div v-if="option.product && option.product" class="product-option">
              <div class="product-card">
                <p><strong>{{ option.product.name }}</strong></p>
                <img v-if="option.product.image" :src="option.product.image.url" alt="Product Image" />
              </div>
          </div>
        </div>
      </div> -->

            <div class="container">
              <hr class="mb-2 mb-md-5" />

              <div class="section-title">
                <div>
                  <p class="title">
                    <span>{{ $t(child.title) }}</span>
                  </p>
                </div>
              </div>

              <div class="products">
                <VueSlickCarousel v-bind="settings">
                  <div v-for="(product, index) in child.options" :key="index">
                    <child-product-home :product="product.product"></child-product-home>
                  </div>
                </VueSlickCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
       <div v-else>
       </div>


      <!-- related_products -->
      <product-section
        type="related_products"
        :products="relatedProducts"
        v-if="relatedProducts && relatedProducts.length > 0"
      ></product-section>
      <!-- related_products -->

      <!-- new_in_the_range -->
      <product-section
        type="new_in_the_range"
        :products="inTheActionProducts"
        v-if="inTheActionProducts"
      ></product-section>
      <!-- new_in_the_range -->

      <!-- in_the_action -->
      <product-section
        type="in_the_action"
        :products="bestSellerProducts"
        v-if="bestSellerProducts"
      ></product-section>
      <!-- in_the_action -->

      <!-- <div class="container">
                <related-products-one :products="relatedProducts"></related-products-one>
            </div> -->
    </div>
  </main>
</template>
<script>


import VueSlickCarousel from "vue-slick-carousel";
import GalleryHorizontal from "@/esf_weert_mobilesupplies/core/components/partial/product/gallery/GalleryHorizontal";
import DetailOne from "@/esf_weert_mobilesupplies/core/components/partial/product/details/DetailOne";
import InfoTwo from "@/esf_weert_mobilesupplies/core/components/partial/product/info-tabs/InfoTwo";
import Breadcrumb from "@/esf_weert_mobilesupplies/core/components/partial/product/BreadCrumb";
// import RelatedProductsOne from '@/esf_weert_mobilesupplies/core/components/partial/product/related/RelatedProductsOne';
// import Repository, { baseUrl } from '@/esf_weert_mobilesupplies/repositories/repository.js';
// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';
import ProductSection from "@/esf_weert_mobilesupplies/core/components/partial/home/ProductSection";
import { mapGetters } from "vuex";
import config from "../../config";
import { isServer } from "@storefront/core/helpers";
import { Logger } from "@storefront/core/lib/logger";
import OverallRating from "@/esf_weert_mobilesupplies/core/components/elements/OverallRating";
import ChildProductHome from '../../components/elements/products/ChildProductHome.vue';


export default {
  components: {
    GalleryHorizontal,
    DetailOne,
    InfoTwo,
    Breadcrumb,
    ProductSection,
    OverallRating,
    ChildProductHome,
    VueSlickCarousel
  },
  props: {
    product: {
      type: Object,
      default: function () {
        return {
          sm_pictures: [],
          pictures: [],
        };
      },
    },
  },
  data: function () {
    return {
      // products: [],
      // product: null,
      // prevProduct: null,
      // nextProduct: null,
      // relatedProducts: [],
      // loaded: false,
      showSuccess: true,
      showInfo: true,
      showWarning: true,
      showError: true,
      selectedConfigProduct: null,
      ratingDialog: false,
      productCache: null,
      // childProduct: null,
      settings: {
        // dots: true,
        arrows: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      },
    };
  },




  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      // productBundles: "product/getCurrentBundle",
    }),
    
    productName() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.name
        : this.product.name;
    },
    getName() {
      return this.selectedConfigProduct
        ? this.selectedConfigProduct.name
        : this.product.name;
    },
    relatedProducts() {
      return this.$store.getters["product/getRelated"];
    },
    bundlesProducts() {
      return this.$store.getters["product/getCurrentBundle"];
    },
    inTheActionProducts() {
      return this.$store.getters["home/getHomeNewSofas"];
    },
    bestSellerProducts() {
      return this.$store.getters["home/getLatestTrendsProducts"];
    },
  },
  methods: {
    updateConfigProduct(sku) {
      console.log(sku, "sku");
      if (sku != null) {
        console.log("run");
        if (this?.productConfig && this.productConfig?.variants) {
          const variant = this?.productConfig?.variants.find(
            (item) => item?.product?.sku === sku
          );
          console.log(variant, "variant");
          if (variant && variant.product) {
            this.selectedConfigProduct = { ...variant?.product };
            this.$store.dispatch("product/loadRelated", {
              sku: variant.product.sku,
            });
            // this.$store.dispatch("productSerie/loadSerie", {
            //     serie_id: variant.product.serie,
            // });
          }
        }
      } else if (this.product) {
        this.selectedConfigProduct = null;
        this.$store.dispatch("product/loadRelated", {
          sku: this.product.sku,
        });
        // this.$store.dispatch("productSerie/loadSerie", {
        //     serie_id: this.product.serie,
        // });
      } else {
        this.selectedConfigProduct = null;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    handleRating() {
      this.ratingDialog = !this.ratingDialog;
    },
    updateProductCache(newProduct) {
      this.productCache = newProduct;
    },
  },
  created: function () {
    this.productCache = this.product;
  },
  mounted() {
    document.addEventListener("click", (event) => {
      if (!event.target.closest(".ratings-container")) {
        this.ratingDialog = false;
      }
    });
    if (!isServer) {
      if (this.$gtm.enabled()) {
        Logger.debug("Watch Product", "product.vue", "CALLED")();
        window?.dataLayer.push({
          event: "eec.detail",
          ecommerce: {
            detail: {
              products: [
                {
                  name: this.product.name,
                  id: this.product.sku,
                },
              ],
            },
          },
        });
      }
    }
  },
  updated() {
  console.log("update", {
      products: this.product,
      bundles: this.productBundles,
      config: this.productConfig,
      relatedProduct: this.relatedProducts,
      childProduct: this.childProduct,
      inTheActionProducts: this.inTheActionProducts,
    });
   console.log(this.productConfig, "this.productConfig");
   },
  watch: {
    product(newProduct) {
      if (!isServer) {
        if (this.$gtm.enabled()) {
          Logger.debug("Watch Product", "product.vue", this.product)();
          window?.dataLayer.push({
            event: "eec.detail",
            ecommerce: {
              detail: {
                products: [
                  {
                    name: this.product.name,
                    id: this.product.sku,
                  },
                ],
              },
            },
          });
        }
      }
      console.log(newProduct, "newProduct");
      if (newProduct) {
        this.updateProductCache(newProduct);
      }
    },
    currentChildSku(sku) {
      console.log(sku);
      this.updateConfigProduct(sku);
    },
  },
  metaInfo() {
    Logger.debug("metaInfo", "Product", config)();
    return {
      meta: [
        { name: "title", content: this.product && this.product.meta_title },
        {
          name: "keywords",
          content:
            this.product && this.product.meta_keyword
              ? this.product.meta_keyword
              : "",
        },
        {
          name: "description",
          content:
            this.product && this.product.meta_description
              ? this.product.meta_description
              : "",
        },
        { property: "og:type", content: "product" },
        {
          property: "og:title",
          content:
            this.product && this.product.meta_title
              ? this.product.meta_title
              : "",
        },
        {
          property: "og:image",
          content:
            this.product && this.product.image.url
              ? this.product.image.url
              : "",
        },
        {
          property: "og:description",
          content:
            this.product && this.product.meta_description
              ? this.product.meta_description
              : "",
        },
        {
          property: "product:price:amount",
          content:
            this.product &&
            this.product.price_range.maximum_price.final_price.value
              ? this.product.price_range.maximum_price.final_price.value.toFixed(
                  2
                )
              : "",
        },
        {
          property: "product:price:currency",
          content: "EUR",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title:
        this.product && this.product.name
          ? this.product.name
          : this.product && this.product.meta_title
          ? this.product.meta_title
          : "",
      link: [
        {
          rel: "canonical",
          href:
            this.product && this.product.canonical_url
              ? config.app_url + this.product.canonical_url
              : config.app_url +
                (/\/$/.test(this.$route.fullPath)
                  ? this.$route.fullPath
                  : this.$route.fullPath.replace(/^\//, "") + "/"),
        },
      ],
    };
  },
};
</script>