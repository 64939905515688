<template>
  <main class="main">
    <!-- <page-header title="Shopping Cart" subtitle="Shop"></page-header> -->
    <nav class="breadcrumb-nav m-0">
      <div class="container">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link to="/">Home</router-link>
          </li>
          <li class="breadcrumb-item active">Shopping Cart</li>
        </ol>
      </div>
    </nav>

    <div class="page-content p-2">
      <div class="cart">
        <div class="container">
          <div class="bg-white br-1 p-4 p-md-5">
            <div class="row" v-if="!cartItems.length" key="noCart">
              <div class="col-12">
                <div class="cart-empty-page text-center">
                  <i class="cart-empty icon-shopping-cart"></i>
                  <p class="px-3 py-2 cart-empty mb-3">
                    No products added to the cart
                  </p>
                  <p class="return-to-shop mb-0">
                    <router-link to="/shop/sidebar/list" class="btn btn-primary"
                      >RETURN TO SHOP</router-link
                    >
                  </p>
                </div>
              </div>
            </div>
            <div v-else key="hasCart">
              <div class="row">
                <div class="col-md-12">
                  <h5 class="text-primary font-weight-bold">{{ $t("shopping_basket") }}</h5>
                  <div class="col-md-12 text-right my-2 d-flex flex-col d-md-none" style="flex-direction: column;align-items: end;">
                    
                    <button class="btn btn-secondary ml-6 mb-1 font-weight-bold">
                      {{ $t("continue_ordering") }}
                    </button>
                    <router-link to="">
                      <font-awesome-icon
                        class="text-primary"
                        icon="fa-solid fa-angle-left"
                      />
                      {{ $t("continue_shopping") }}
                    </router-link>
                  </div>
                  <table class="table table-cart table-mobile">
                    <thead>
                      <tr>
                        <th class="text-dark">
                          <strong>{{ $t("Article") }}</strong>
                        </th>
                        <th class="text-dark">
                          <strong>{{ $t("price") }}</strong>
                        </th>
                        <th class="text-dark">
                          <strong>{{ $t("number") }}</strong>
                        </th>
                        <th class="text-dark">
                          <strong>{{ $t("subtotal") }}</strong>
                        </th>
                        <th class="text-dark"></th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr v-for="(item, index) in cartItems" :key="index">
                        <td class="product-col">
                          <div class="product">
                            <figure class="product-media">
                              <router-link :to="'/' + item.product.url_key">
                                <img
                                  :src="item.product.thumbnail.thumbnail"
                                  alt="Product"
                                />
                              </router-link>
                            </figure>

                            <h3 class="product-title">
                              <router-link :to="'/' + item.product.url_key">{{
                                item.product.name
                              }}</router-link>
                              <p class="status">{{ $t("in_stock") }}</p>
                            </h3>
                          </div>
                        </td>
                        <td class="d-block d-md-none px-4">
                          <div
                            class="d-flex align-items-center justify-content-between"
                          >
                            <quantity-input
                              :product="item"
                              class="cart-product-quantity m-0"
                              @change-qty="changeQuantity"
                            >
                            </quantity-input>
                            <h6 class="mb-0">
                              <strong
                                >
                                {{
                                  formatCurrency(
                                    item.product.price_range.maximum_price
                                      .final_price.value
                                  )
                                }}</strong
                              >
                            </h6>
                          </div>
                        </td>
                        <td class="price-col custom_relative">
                          {{
                            formatCurrency(
                              item.product.price_range.maximum_price.final_price
                                .value
                            )
                          }}
                        </td>
                        <td class="quantity-col custom_relative">
                          <quantity-input
                            :product="item"
                            class="cart-product-quantity"
                            @change-qty="changeQuantity"
                          >
                          </quantity-input>
                        </td>
                        <td class="total-col custom_relative">
                          {{
                            formatCurrency(
                              item.prices.price_incl_tax.value * item.quantity
                            )
                          }}
                        </td>
                        <td class="remove-col custom_relative">
                          <button
                            class="btn-remove pb-5 pl-5"
                            @click.prevent="
                              $bvModal.show('product-delete');
                              removeProduct(item.id);
                            "
                          >
                            <!-- @click.prevent="removeFromCart({ product: product })" -->
                            <font-awesome-icon
                              class="text-primary"
                              icon="fa-solid fa-trash"
                            />
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row justify-content-between">
                <div class="col-md-6">
                  <router-link to="">
                    <font-awesome-icon
                      class="text-primary"
                      icon="fa-solid fa-trash"
                    />
                    {{ $t("clear_all") }}
                  </router-link>
                </div>
                <div class="col-md-4 text-right">
                  <p class="text-dark">
                    {{ $t("subtotal") }}: <span class="ml-4">{{
                      formatCurrency(cartPrices.subtotal_including_tax.value)
                    }}</span>
                  </p>
                  <p class="text-dark">
                    {{ $t("shipping_cost") }}: <span class="ml-4">{{
                      formatCurrency(shippingCost)
                      
                    }}</span>
                  </p>
                  <hr class="my-2" />
                  <h5 class="mb-0 mt-1 mb-md-1 text-dark">
                    <strong>{{ $t("total1") }}:
                    <span class="ml-4">{{
                      formatCurrency(cartPrices.grand_total.value)
                    }}</span></strong
                    >
                  </h5>
                  <div class="col-md-12 mt-4 d-flex justify-content-end">
                    <router-link to="/" class="d-none d-md-block">
                      <font-awesome-icon
                        class="text-primary"
                        icon="fa-solid fa-angle-left"
                      />
                      {{ $t("go_back") }}
                    </router-link>
                    <router-link to="/order/">
                      <button class="btn btn-secondary d-none d-md-inline ml-4">
                        {{ $t("continue_ordering") }}
                      </button>
                    </router-link>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 mt-2 mt-md-0">
                  <div class="cart-bottom ">
                    <div class="cart-discount w-100" style="max-width: 100%;">
                      <form action="#">
                        <div class="input-group">
                          <input
                            type="text"
                            class="form-control m-0"
                            required
                            :placeholder="$t('coupon_code')"
                          />
                          <div class="input-group-append">
                            <button class="btn text-white" type="submit" style="background-color: #343434;">
                              <span>{{ $t("add") }}</span>
                              <!-- <i class="icon-long-arrow-right"></i> -->
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" class="w-100">
                  <div class="p-4 text-center font-weight-bold mb-3" style="background-color: #e1e2e6;">
                    <strong v-if="freeShippingAmount <= 0">{{
                      $t("free_shipping_msg_free")
                    }}</strong>
                    <strong v-else>{{
                      $t("order_pay_no_shipping_costs", {
                        amount: formatCurrency(freeShippingAmount),
                      })
                    }}</strong>
                  </div>
                </div>
                <div class="col-md-12 text-right d-flex flex-col d-md-none" style="flex-direction: column;align-items: end;">
                    
                    <button class="btn btn-secondary ml-6 mb-1 font-weight-bold">
                      {{ $t("continue_ordering") }}
                    </button>
                    <router-link to="">
                      <font-awesome-icon
                        class="text-primary"
                        icon="fa-solid fa-angle-left"
                      />
                      {{ $t("continue_shopping") }}
                    </router-link>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- related_products -->
      <product-section
        type="related_products"
        :products="relatedProducts"
        v-if="relatedProducts.length"
      ></product-section>
      <!-- related_products -->

      <!-- new_in_the_range -->
      <!-- <product-section
        type="new_in_the_range"
        :products="newInTheRange"
        v-if="newInTheRange.length"
      ></product-section> -->
      <!-- new_in_the_range -->

      <!-- in_the_action -->
      <product-section
        type="in_the_action"
        :products="inTheAction"
        v-if="inTheAction.length"
      ></product-section>
      <!-- in_the_action -->
    </div>

    <!-- remove product in cart modal -->
    <b-modal
      id="product-delete"
      class="product-modal-inner"
      centered
      hide-footer
      content-class="defaul-modal"
      size="lg"
    >
      <template #modal-title class="bold">Remove Product</template>
      <div class="modal-text d-block border-bottom text-center">
        <p>Are you sure to remove this product?</p>
      </div>
      <div class="modal-btn-box pt-2">
        <b-button
          class="btn-success"
          block
          @click="
            $bvModal.hide('product-delete');
            removeItem(productID);
          "
          >Ok</b-button
        >
        <b-button
          class="btn-success btn-outline"
          block
          @click="$bvModal.hide('product-delete')"
          >{{ $t("cancel") }}</b-button
        >
      </div>
    </b-modal>
    <!-- remove product in cart modal -->
  </main>
</template>

<script>
// import { mapGetters, mapActions, mapMutations } from 'vuex';
// import PageHeader from '@/esf_weert_mobilesupplies/core/components/elements/PageHeader';
import ProductSection from "@/esf_weert_mobilesupplies/core/components/partial/home/ProductSection";
import QuantityInput from "@/esf_weert_mobilesupplies/core/components/elements/QuantityInput.vue";
import Cart from "@storefront/core/modules/cart/mixins";
import product from '../../../../store/modules/product';

export default {
  components: {
    ProductSection,
    // PageHeader,
    QuantityInput,
  },
  mixins: [Cart,product],
  data: function () {
    return {
      productID: "",
    };
  },
  mounted: function () {
    this.$store.dispatch("cart/getFreeShipping");
    this.$store.dispatch('productSerie/fetchFreeShipping');
  },
  computed: {
    // ...mapGetters('cart', ['cartList', 'priceTotal', 'qtyTotal'])
    cartItems() {
      return this.$store.getters["cart/getCartItems"];
    },
    cartPrices() {
      return this.$store.getters["cart/getCartPrices"];
    },
    shippingCost() {

      return this.$store.getters["cart/getFreeShippingAmount"];
    },

    freeShippingAmount() {
      const subtotalIncludingTax = this.cartPrices.subtotal_including_tax.value || 0;
      const defaultAmount = this.$store.getters['productSerie/getDefaultShippingAmount'] || 0;      
      return  defaultAmount - subtotalIncludingTax ;
    },

    // includeTax() {
    //   console.log('aaaaa',this.$store.getters['productSerie/getIncludeTax']);
    //   return this.$store.getters['productSerie/getIncludeTax'];
    // },
    // // Access countries eligible for free shipping
    // freeShippingCountries() {
    //   return this.$store.getters['productSerie/getFreeShippingCountries'];
    // },

    newInTheRange() {
      return this.$store.getters["home/getHomeNewSofas"];
    },
    inTheAction() {
      return this.$store.getters["home/getLatestTrendsProducts"];
    },
    relatedProducts() {
      return this.$store.getters["product/getRelated"];
    },
  },
  // watch: {
  // cartList: function () {
  //  this.cartItems = [...this.cartList];
  // }
  // },
  // created: function () {
  //     this.cartItems = [...this.cartList];
  // },
  // methods: {
  //     // ...mapActions('cart', ['removeFromCart']),
  //     // ...mapActions('cart', ['updateCart']),
  //     changeQty: function (value, product) {
  //         this.cartItems = this.cartItems.reduce((acc, cur) => {
  //             if (cur.name == product.name)
  //                 return [
  //                     ...acc,
  //                     {
  //                         ...cur,
  //                         qty: value,
  //                         sum:
  //                             (product.sale_price
  //                                 ? product.sale_price
  //                                 : product.price) * value
  //                     }
  //                 ];
  //             return [...acc, cur];
  //         }, []);
  //     }
  // }

  methods: {
    formatCurrency(amount) {
      
      return this.$helpers.formatCurrency(amount);
    },
    changeQuantity(qty, id) {
      if (qty > 0) {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: qty,
        });
      } else {
        this.$store.dispatch("cart/updateCartItem", {
          id: id,
          quantity: 1,
        });
      }
      this.updateCrossSell();
    },
    updateCrossSell() {
      let skuList = [];
      this.cartItems.forEach((cartItem) => {
        if (cartItem.product && cartItem.product.sku) {
          skuList.push(cartItem.product.sku);
        }
      });
      this.$store.dispatch("product/loadCrossSellForCart", { skus: skuList });
    },
    removeProduct(data) {
      this.productID = data;
      this.updateCrossSell();
    },
  },
};
</script>